@import url("./font_3347340_qro4dyqbgpi.css");
/* @import url('https://fonts.googleapis.com/css2?family=Poppins-Bold:wght@400;600&display=swap');
 */
 /* @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@400;600&family=Poppins-Bold:wght@700&display=swap'); */
 /* @import url('https://fonts.googleapis.com/css2?family=Poppins-Bold:wght@600&display=swap'); */

  /* @import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@400;700&family=Golos+Text:wght@800;900&family=Poppins:wght@200&family=Roboto:wght@500;700&display=swap'); */

  /* @import url('https://fonts.google.com/noto/specimen/Noto+Sans+Display?query=Noto+Sans+Display'); */
  @font-face {
    font-family: "Noto Sans Display";
    src: url("./fonts/NotoSansDisplay-Regular.ttf");
  
  }
  @font-face {
    font-family: "Noto Sans Display Blod";
    src: url("./fonts/NotoSansDisplay-Bold.ttf");
  
  }
/* 
@font-face {
  font-family: Manrope;
  src: url("./fonts/Manrope/Manrope-Bold.ttf");

}

@font-face {
  font-family: Manrope-Bold;
  src: url("./fonts/Manrope/Manrope-Bold.ttf");

}

@font-face {
  font-family: Manrope-SemiBold;
  src: url("./fonts/Manrope/Manrope-SemiBold.ttf");
}

@font-face {
  font-family: PingRC;
  src: url("./fonts/PingRe.ttf");
}



@font-face {
  font-family: Popins;
  src: url("/fonts/Popins/Poppins-Regular.ttf");

}

@font-face {
  font-family: Popins-Bold;
  src: url("/fonts/Popins/Poppins-Bold.ttf");

}

@font-face {
  font-family: PopinsSemi;
  src: url("/fonts/Popins/Poppins-SemiBold.ttf");

}



@font-face {
  font-family: Poppins-Regular;
  src: url("/fonts/Popins/Poppins-Regular.ttf");

}

@font-face {
  font-family: Poppins-Bold;
  src: url("/fonts/Popins/Poppins-Bold.ttf");

} */





/* 首页大小通用适配 不要删！！！！！*/
.defeaultContainer {
  width: 1400px;
  margin: 0 auto;
}

@media screen and (min-width:1600px) {
  .defeaultContainer {
    width: 1400px;
  }
}

/* _$max - (($maxScreen - 100vw) / (($maxScreen - $minScreen) / ($max - $min))); */
/* 公式含义媒体查询，没减少1px 需要从最大值减少多少px 实现从一个区间，最大值到最小值的自然过渡 */
/* 大于1366 保持padding 100px */
@media screen and (max-width:1600px) and (min-width:1366px) {
  .defeaultContainer {
    width: calc(1460px - ((1600px - 100vw) / (1)));
    /*1400px -> 1166px padding 100px*/
  }
}

/* $max - (($maxScreen - 100vw) / (($maxScreen - $minScreen) / ($max - $min))); */
@media screen and (max-width:1366px) and (min-width:1024px) {
  .defeaultContainer {
    width: calc(1166px - ((1366px - 100vw) / (86 / 46)));
    /*1166px -> 1120px padding 100px -> 80*/
  }
}

/* $max - (($maxScreen - 100vw) / (($maxScreen - $minScreen) / ($max - $min))); */
@media screen and (max-width:1024px) and (min-width:1024px) {
  .defeaultContainer {
    width: calc(1120px - ((1024px - 100vw) / (256 / 158)));
    /*1120px -> 962px padding 80px -> 30*/
  }
}

@media screen and (max-width:1024px) {
  .defeaultContainer {
    width: 100%;
    /*1120px -> 962px padding 80px -> 30*/
  }
}

/*
修改视频modal 样式
*/
/* .myModal  */

.myWrap .ant-modal-content {
  border-radius: 8px;
}

@media screen and (max-width: 1400px) {
  .myWrap .ant-modal {
    top: 0 !important;
    transform: scale(0.8);
  }
}

/* .iframe_video {
  max-width: 325px;
  width: 325px;
  height: 580px;
  /* width: 300px;
  height: 628px; */
/* height: 70vh;
  width: calc(70vh * 0.56034448) !important;
} */

/* 分页样式修改 */
.pa_container {
  display: flex;
  align-items: center;
}

.pa_li {
  width: 32px;
  height: 32px;
  border: 1px solid #d9d9d9;
  line-height: 32px;
  border-radius: 2px;
  margin: 4px;
  display: flex;
  justify-content: center;
}

.pa_li a {
  color: rgba(0, 0, 0, 0.85);
}

.pa_a {}

.pa_active {
  border-color: #1890ff;
}

.pa_active_a {
  color: #1890ff;
}

.pa_prev a {
  color: rgba(0, 0, 0, 0.25);
}

.pa_next a {
  color: rgba(0, 0, 0, 0.25);
}

.pa_break {
  color: rgba(0, 0, 0, 0.25);
}

.mobile-nav-in {
  animation: slideInLeft 500ms ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transform-origin: bottom center;
}

.mobile-nav-out {
  animation: slideOutLeft 500ms ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transform-origin: bottom center;
}

.mobile .mobile-nav {
  display: block !important;
}

.modal-mask {
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  transition: all 500ms ease-in-out;
  z-index: 9;
  display: block;
}

.mobileModal {
  display: none;
}

.popins-title {
  font-family: Popins-Bold;
}

.nav-scroll {
  background: #fff !important;
  transition: all 200ms ease-in-out;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);

}

.nav-hover {
  background: #fff !important;
  transition: all 200ms ease-in-out;
  box-shadow: none !important;
}

.tikmeta-btn {
  background: #ff3e5f;
  box-shadow: 0px 10px 14px -4px #f2b8c5;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}

.tikmeta-btn:hover {
  background: #ff667a;
}

.animation-living {
  display: inline-block;
  position: absolute;
  display: none;
  text-align: center;
  height: 48px;
  font-size: 0;
  white-space: nowrap;
  margin-right: 6px;
  vertical-align: baseline;
  -webkit-animation: scy 1s alternate forwards;
  animation: scy 1s alternate forwards;
  top: 15px;
  left: 47px;
}

.animation-living .item {
  display: inline-block;
  width: 8px;
  height: 100%;
  /* -webkit-transform-origin: bottom; */
  transform-origin: bottom;
  /* border-radius: 2px; */
  background: linear-gradient(180deg, #fff, #fff);
  border-radius: 20px;
  margin-right: 4px;
}

.animation-living .item-ani {
  -webkit-animation: animate-living 0.9s linear infinite;
  animation: animate-living 0.9s linear infinite;
}

.animation-living .item1 {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.animation-living .item2 {
  /* margin-bottom: -3px; */
}

.animation-living .item3 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  /* margin-bottom: -6px; */
}

.animation-living .item4 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  /* margin-bottom: -8px; */
}



.ellipse-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.ellipse-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.ellipse-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

/* .animation-living .item5 {
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
  margin-bottom: -12px;
} */


@keyframes scy {
  0% {
    opacity: 0;
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
}

@keyframes animate-living {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }

  50% {
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8);
  }

  100% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
}

.jump3 {
  -webkit-animation: jump2 2s infinite ease;
  animation: jump2 2s infinite ease;
}

@keyframes jump2 {
  0% {
    margin-top: 0;
  }

  50% {
    margin-top: -30px;
  }

  100% {
    margin-top: 0;
  }
}

@media screen and (max-width: 900px) and (min-width: 670px) {
  /* .tools .swiper-slide {
    width: 40% !important;
    margin: 0 5%;
  }

  .tools .swiper-slide-prev {
    right: -8%;
  }

  .tools .swiper-slide-next {
    left: -8%;
  }

  .tools .swiper-slide-next+div {
    left: -16%;
  } */
}

@media screen and (max-width: 670px) {
  /* .tools .swiper-slide {
    width: 90% !important;
    margin: 0 5%;
  }

  .tools .swiper-slide-prev {
    right: -8%;
  }

  .tools .swiper-slide-next {
    left: -8%;
  } */
}

.ant-btn-primary {
  border-color: #ff3e5f !important;
  background: #ff3e5f !important;
}

.tools .swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.tools .swiper-pagination-bullet-active {
  background-color: #fff !important;
}



@media screen and (min-width:1024px) {
  html {
    font-size: 37.5px;
  }

  body {
    font-size: 13.5px !important;
  }

}



.text-\[37\.5Px\] {
  font-size: 37.5Px;
}

@media (min-width: 1024px) {
  .minlg1024\:\!text-\[37\.5Px\] {
    font-size: 37.5Px !important;
  }
}

/* @media (max-width: 1024px) {
  .minlg1024\:\!text-\[37\.5Px\] {
    font-size: 37.5Px !important;
  }
} */

.cm-in {
  animation: fadeInUprelax 800ms ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transform-origin: bottom center;
}

.cm-y {
  animation: fadeInYrelax 500ms ease;

  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  transform-origin: bottom center;
}

.ant {
  opacity: 0;
}

.ant-y {
  transform: scaleY(0);
}

.cm-in-chart,
.cm-in-number {
  opacity: 1;
}

@keyframes fadeInYrelax {
  from {
    transform: scaleY(0);
    transform-origin: bottom;
  }

  to {
    transform: scaleY(1);
    transform-origin: bottom;
  }
}

@keyframes fadeInUprelax {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

a:hover {
  opacity: 0.8;
}

body {
  min-height: 100vh;
}

@media screen and (max-width: 1024px) {
  .dvmobile {
    width: 100% !important;
  }
}

/* 私域 */
.ContactModal .ant-modal {
  height: 316px;
  pointer-events: auto;
}

.QuestionModal .ant-modal {
  /*height: 500px;*/
  pointer-events: auto;
}

.copy-message .ant-message-notice-content{
  color: #fff;
  background-color: rgba(17,17,17,.7);
  border-radius: 5px;
  box-shadow: none;
}

.linear-animation .swiper-wrapper {
  transition-timing-function: linear;
}