.search-box {
    margin-top: 56px;
    width: 560px;
}

.search-box .search-nav ul {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}


.search-box .search-nav ul li {
    height: 44px;
    line-height: 44px;
    min-width: 80px;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #FE3057;
}

.search-box .search-bar .search-icon .svg-icon {
    display: inline-block;
    width: 24px;
    height: 22px;
    vertical-align: middle;
}

.search-box .search-nav ul li.active {
    background: #FE3057;
    border-radius: 8px 8px 0px 0px;
    color: #FFFFFF;
}


.search-box .search-bar {
    position: relative;
    width: 100%;
    height: 53px;
    background: #FFFFFF;
}

.search-box .search-bar [type="text"] {
    width: 100%;
    height: 53px;
    line-height: 53px;
    padding-right: 64px;
    padding-left: 9px;
    border-radius: 4px 0 0 4px;
    text-align: left;
    font-size: 15px;
    font-weight: 400;
    color: #7F7F7F;
    background: #FFFFFF;
    border-radius: 0px 8px 8px 8px;
    box-shadow: 0px 11px 23px 0px rgba(0, 0, 0, 0.04);
    border: 2px solid #fff;
    box-sizing: border-box;
}

.search-box .search-bar .search-icon {
    position: absolute;
    right: 24px;
    top: 16px;
    display: block;
}

.search-box .search-bar .btn-search-submit {
    text-align: center;
    height: 53px;
    line-height: 53px;
    width: 72px;
    font-size: 16px;
    color: #fff;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    background: #FE3057;
    border-radius: 0px 8px 8px 0px;
}




@media screen and (max-width: 1180px) and (min-width: 768px) {
    .search-box {
        /* margin-top: 56px; */
        width: 382px;
    }

    .search-box .search-nav ul li {
        font-size: 14px;
    }

    .search-box .search-bar [type="text"] {
        font-size: 14px;
    }

}

@media screen and (max-width: 768px) and (min-width: 375px) {
    .search-box {
        /* margin-top: 56px; */
        width: 382px;
    }
}

@media screen and (max-width:375px) {
    .search-nav {
        display: none;
    }

    .search-box {
        /* margin-top: 56px; */
        width: 332px;
    }
}